import { api_base_url } from '../../global_data/index';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import axios from 'axios';

const handleLoginClick = ({ setUserGlobalState, setFeedbackState }) => {
  var provider = new firebase.auth.OAuthProvider('apple.com');

  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (result) => {
      setFeedbackState({
        isActive: true,
        isLoading: true
      });

      // /** @type {firebase.auth.OAuthCredential} */
      const credential = result.credential;

      // // The signed-in user info.
      const user = result.user;

      // // You can also get the Apple OAuth Access and ID Tokens.
      // var accessToken = credential.accessToken;

      const idToken = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);

      // const idToken = credential.idToken;

      const { displayName, email, uid } = user;

      localStorage.setItem('tgtxc5', idToken);

      const artist = await handleLoginOnFirestore({
        artistData: { user: uid, name: displayName || 'User', appleId: email }
      });

      setUserGlobalState({ user: { _id: uid }, artist, isValid: true });

      navigate('/dashboard/myDecks?artistId=' + artist._id);

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Successful Login'
      });
    })
    .catch((error) => {
      // // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;

      // ...

      console.log('err', error);

      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'Error'
      });
    });
};

const handleLoginOnFirestore = async ({ artistData }) => {
  const res = await axios({
    url: `${api_base_url}/artists/getOrCreateArtist`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    },
    data: JSON.stringify(artistData)
  });

  return res.data;
};

export default handleLoginClick;
