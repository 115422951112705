
import React from "react";
import styles from "./Avatar.module.css";

const Avatar = ({ imgUrl }) => {

  return (
    <div style={{ backgroundImage: `url("${imgUrl}"), url('/images/avatar-placeholder.png`}} className={styles.avatar}>
        {/* Empty */}
    </div>
  )
}

export default Avatar;
