
import { navigate } from "gatsby";
import logout from '../../../../utils/logout'

const handleLogoutClick = ({ setUserGlobalState }) => {

    navigate('/');
    setTimeout(() => {
        logout({ setUserGlobalState });
    }, 1000);
    
}


export default handleLogoutClick;