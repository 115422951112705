
const logout = ({ setUserGlobalState }) => {

    localStorage.removeItem('tgtxc5');

    setUserGlobalState({ artist: null, user: null, isValid: false})

}

export default logout;

