
import React from "react";
import styles from './CenterContainer.module.css'

const CenterContainer = ({ children }) => {
    return (
        <div className={styles.CenterContainer}>
            { children }
        </div>
    )
}

export default CenterContainer;
