import is_client_browser from './is_client_browser';

const isUrl = (pathname) => {
  if (
    is_client_browser() &&
    window.location.pathname + (window.location.hash || '') == pathname
  ) {
    return true;
  } else {
    return false;
  }
};

export default isUrl;
