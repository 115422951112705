// DO NOT LOGG ON CATCH, IF THIS FUNCTION CATCHES IT MEANS WE ARE ON NETLIFY AND THAT MIGHT BE NORMAL
const is_client_browser = () => {
  try {
    if (window) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export default is_client_browser;
