import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import styles from './Navbar.module.css';
import handleLoginClick from '../../../utils/handleLoginClick';
import handleLogoutClick from './utils/handleLogoutClick';
import { UserContext } from '../../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import Avatar from '../../components/Avatar/Avatar';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '../../components/Button/Button';
import isUrl from '../../../utils/isUrl';

const Navbar = () => {
  const { userGlobalState, setUserGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);
  const [profilePopupAnchor, setProfilePopupAnchor] = useState(null);

  const isProfilePopOpen = Boolean(profilePopupAnchor);

  const handleClose = () => {
    setProfilePopupAnchor(null);
  };

  const triggerProfilePopup = (event) => {
    setProfilePopupAnchor(event.currentTarget);
  };

  return (
    <div className={styles.nav__container}>
      <div>
        <Link to="/">
          <div className={styles.logo__desktop}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1149.24 260">
              <defs></defs>
              <g id="Fg">
                <path
                  class="cls-1"
                  d="M710.82,54.37a7.5,7.5,0,0,0-7.5,7.5v125a7.5,7.5,0,0,0,15,0v-125A7.5,7.5,0,0,0,710.82,54.37Z"
                />
                <path
                  class="cls-1"
                  d="M292.32,66.87a7.5,7.5,0,0,0,0-15h-105a7.5,7.5,0,1,0,0,15h45v120a7.5,7.5,0,0,0,15,0v-120Z"
                />
                <path
                  class="cls-1"
                  d="M357.31,146.87a47.59,47.59,0,1,0-15,34.59v5.41a7.5,7.5,0,1,0,15,0V148.05h0C357.29,147.65,357.31,147.26,357.31,146.87Zm-47.5,32.5a32.5,32.5,0,1,1,32.5-32.5A32.5,32.5,0,0,1,309.81,179.37Z"
                />
                <path
                  class="cls-1"
                  d="M457.31,146.87a47.59,47.59,0,1,0-15,34.59v12.91a32.5,32.5,0,0,1-52.42,25.68,7.4,7.4,0,0,0-9.43.41,7.49,7.49,0,0,0,.4,11.54,47.47,47.47,0,0,0,76.45-37.63v-45h-.06C457.29,148.54,457.31,147.71,457.31,146.87Zm-47.5,32.5a32.5,32.5,0,1,1,32.5-32.5A32.5,32.5,0,0,1,409.81,179.37Z"
                />
                <path
                  class="cls-1"
                  d="M549.81,101.87a47.5,47.5,0,1,0,47.5,47.5A47.56,47.56,0,0,0,549.81,101.87Zm0,80a32.5,32.5,0,1,1,32.5-32.5A32.5,32.5,0,0,1,549.81,181.87Z"
                />
                <path
                  class="cls-1"
                  d="M649.81,101.87a47.5,47.5,0,1,0,47.5,47.5A47.56,47.56,0,0,0,649.81,101.87Zm0,80a32.5,32.5,0,1,1,32.5-32.5A32.5,32.5,0,0,1,649.81,181.87Z"
                />
                <path
                  class="cls-1"
                  d="M504.82,109.37a7.5,7.5,0,0,0-7.5-7.5h-10v-20a7.5,7.5,0,0,0-15,0v20h-10a7.5,7.5,0,0,0,0,15h10v70a7.5,7.5,0,1,0,15,0v-70h10A7.5,7.5,0,0,0,504.82,109.37Z"
                />
                <path
                  class="cls-1"
                  d="M172.64,97a7.47,7.47,0,0,0-14.94,0v40.73a77.7,77.7,0,0,0-29.85-21.66V42.9a7.47,7.47,0,0,0-14.94,0v68.72A79.31,79.31,0,0,0,98,110.12L98,28A7.47,7.47,0,0,0,83,28l0,83.61A78.85,78.85,0,0,0,68.11,116V42.9a7.47,7.47,0,0,0-14.94,0l0,81.2a76.75,76.75,0,0,0-14.94,13.44V72.77a7.47,7.47,0,0,0-7.47-7.46,7.48,7.48,0,0,0-7.47,7.46l0,93.19a1,1,0,0,0,0,.16,5.45,5.45,0,0,0,0,.7,5.61,5.61,0,0,0,0,.71,7.1,7.1,0,0,0,.17.92c0,.15,0,.3.09.46l0,.1.06.15c9.4,31.21,39.86,53,74.19,53h0c34.41,0,65-21.89,74.29-53.23,0-.1,0-.2.05-.3a6.38,6.38,0,0,0,.15-.76,6.48,6.48,0,0,0,.08-.77c0-.1,0-.2,0-.3v-1.07a8.09,8.09,0,0,0,.15-1.49ZM97.91,207.2h0c-27.07,0-51.06-16.53-59.24-40.45,8.17-24.64,32.18-41.69,59.25-41.7s51.08,17,59.27,41.69C149,190.66,125,207.2,97.91,207.2Z"
                />
                <path
                  class="cls-1"
                  d="M98,140a26.14,26.14,0,1,0,26.14,26.14A26.17,26.17,0,0,0,98,140Zm0,37.34a11.2,11.2,0,1,1,11.2-11.2A11.21,11.21,0,0,1,98,177.33Z"
                />
                <path
                  class="cls-1"
                  d="M848,176.22H832.89a29,29,0,0,1-14.68-4.37c-5.47-3.49-12-10.65-12-24.94,0-14.77,8.07-22.29,14.84-26a34,34,0,0,1,16.32-4H848v8H837.38a26,26,0,0,0-12.48,3c-7.09,3.87-10.68,10.26-10.68,19,0,20.71,16.76,21.31,18.67,21.31H848Z"
                />
                <polygon
                  class="cls-1"
                  points="899.55 176.22 862.44 176.22 862.44 116.89 870.44 116.89 870.44 168.22 899.55 168.22 899.55 176.22"
                />
                <path
                  class="cls-1"
                  d="M933.19,177.37A30.81,30.81,0,1,1,964,146.56,30.85,30.85,0,0,1,933.19,177.37Zm0-53.62A22.81,22.81,0,1,0,956,146.56,22.83,22.83,0,0,0,933.19,123.75Z"
                />
                <path
                  class="cls-1"
                  d="M997.61,177.37c-18,0-22.72-13.25-22.72-20.26V116.89h8v40.22c0,1.26.41,12.26,14.72,12.26,14.65,0,15-11.48,15-11.59V116.89h8v40.89C1020.66,162.86,1016.43,177.37,997.61,177.37Z"
                />
                <path
                  class="cls-1"
                  d="M1057.11,176.22H1034V116.89h23.33c7.29,0,26.22,5.43,26.22,30.24C1083.55,171,1064.46,176.22,1057.11,176.22Zm-15.11-8h15.11c.18,0,18.44-.24,18.44-21.09,0-22-18-22.24-18.22-22.24H1042Z"
                />
                <path
                  class="cls-1"
                  d="M1052.23,223.57H837.39a76.45,76.45,0,0,1,0-152.89h214.84a76.45,76.45,0,0,1,0,152.89ZM837.39,78.68a68.45,68.45,0,0,0,0,136.89h214.84a68.45,68.45,0,0,0,0-136.89Z"
                />
              </g>
            </svg>
          </div>

          <div className={styles.logo__mobile}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
              <defs></defs>
              <g id="Fg">
                <path
                  class="cls-1"
                  d="M244.68,212.91V145.67a7.47,7.47,0,0,0-7.47-7.46h0a7.46,7.46,0,0,0-7.47,7.46V186.4a77.7,77.7,0,0,0-29.85-21.66l0-73.16a7.47,7.47,0,0,0-7.47-7.47h0a7.46,7.46,0,0,0-7.46,7.47V160.3A79.4,79.4,0,0,0,170,158.8l0-82.15a7.47,7.47,0,0,0-7.47-7.47h0a7.46,7.46,0,0,0-7.47,7.47l0,83.61a78.85,78.85,0,0,0-14.94,4.41V91.58a7.48,7.48,0,0,0-7.47-7.47h0a7.47,7.47,0,0,0-7.47,7.47l0,81.2a76.8,76.8,0,0,0-14.94,13.45l0-64.77a7.46,7.46,0,0,0-7.46-7.47h0a7.46,7.46,0,0,0-7.46,7.47l0,93.18c0,.06,0,.1,0,.16,0,.23,0,.47,0,.7s0,.47,0,.71a8.81,8.81,0,0,0,.18.92,3.73,3.73,0,0,0,.09.46l0,.1a.88.88,0,0,1,0,.15c9.41,31.21,39.87,53,74.2,53h0c34.41,0,65-21.89,74.29-53.23,0-.1,0-.2.05-.3a6.22,6.22,0,0,0,.15-.75c0-.26.06-.52.07-.78,0-.1,0-.2,0-.3V214.4h0A7.21,7.21,0,0,0,244.68,212.91Zm-74.75,43h0c-27.07,0-51.07-16.54-59.24-40.46,8.17-24.64,32.18-41.69,59.25-41.7s51.08,17.05,59.26,41.7C221,239.34,197,255.89,169.93,255.89Z"
                />
                <path
                  class="cls-1"
                  d="M170,188.67a26.14,26.14,0,1,0,26.14,26.14A26.17,26.17,0,0,0,170,188.67ZM170,226a11.2,11.2,0,1,1,11.2-11.2A11.21,11.21,0,0,1,170,226Z"
                />
              </g>
            </svg>
          </div>
        </Link>
      </div>
      <div className={styles.nav__rightContainer}>
        <Link
          style={
            isUrl('/#public-artworks')
              ? { fontWeight: '600', color: 'white' }
              : {}
          }
          to="/#public-artworks"
        >
          <span>Public Artworks</span>
        </Link>

        <Link
          style={
            isUrl('/#artists')
              ? { fontWeight: '600', color: 'white' }
              : {}
          }
          to="/#artists"
        >
          <span>Artists</span>
        </Link>

        {userGlobalState && userGlobalState.artist ? (
          <>
            <Link
              style={
                isUrl('/dashboard/myDecks')
                  ? { fontWeight: '600', color: 'white' }
                  : {}
              }
              className={styles.myDecks}
              to={`/dashboard/myDecks?artistId=${userGlobalState.artist._id}`}
            >
              My Decks
            </Link>

            <div style={{ cursor: 'pointer' }} onClick={triggerProfilePopup}>
              <Avatar imgUrl={userGlobalState.artist.avatarImgUrl} />
            </div>
            <Popper
              id={isProfilePopOpen ? 'simple-popover' : undefined}
              open={isProfilePopOpen}
              anchorEl={profilePopupAnchor}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <ul className={styles.nav__profilePopup}>
                  <li className={styles.myDecksPop}>
                    <Link
                      to={`/dashboard/myDecks?artistId=${userGlobalState.artist._id}`}
                    >
                      My Decks
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/profile">Profile</Link>
                  </li>
                  <li
                    onClick={handleLogoutClick.bind(null, {
                      setUserGlobalState
                    })}
                  >
                    Log Out
                  </li>
                </ul>
              </ClickAwayListener>
            </Popper>
          </>
        ) : (
          <div style={{ cursor: 'pointer' }}>
            <Button
              onClick={handleLoginClick.bind(null, {
                setUserGlobalState,
                setFeedbackState
              })}
            >
              Log In
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
