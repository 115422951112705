
import React from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        color: '#ffffffeb',
        border: '1.4px solid white',
        borderColor: '#fbf9f9ab',
        borderRadius: '1px'
    }
});

const ButtonComponent = (props) => {

    const classes = useStyles();

    return (
        <Button {...props} variant="outlined" classes={{
            root: classes.root
        }}/>
    )
}

export default ButtonComponent;
